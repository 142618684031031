<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="customs" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['name','code']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :lazy="true" :loading="loading" :paginator="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="清除" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:25%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入客户名称或代码模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.simplename" @click="openCustom(slotProps.data)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :pageJsonConfig="pageJsonConfig" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="上传导入数据" v-model:visible="importDisplay" :style="{width: '30vw'}" :maximizable="true" :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <Dropdown id="importtemplateFileType" v-model="importTemplateId" :options="excelTemplate"
                    optionLabel="name" optionValue="code" placeholder="请选择导入类型" />
            </div>
            <div class="field col-12 md:col-12">
                <Button label="导入模板下载" @click="getTemplateFilePatch" class="p-button-link" />
            </div>
            <div class="field col-12 md:col-12">
                <FileUpload mode="basic" style="width:100%" id="importtemplateFileId" name="importtemplateFile"
                    :url="uploadurl" accept=".xlsx" :maxFileSize="20971520" @upload="onImportUpload"
                    @before-upload="beforeImportUpload" :auto="true" invalidFileSizeMessage="{0}: 文件大小必须小于20M."
                    invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件" chooseLabel="请选择上传EXCEL文件" />
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImport" />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import customPageJson from "@/data/customConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '688';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayQueryPlan: false,
                displayMaximizable: false,
                importDisplay: false,
                pageJsonConfig: {},
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                currentRow: {
                    bindpage: 'crmcustom',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                uploadurl: '',
                selectedRow: ref(),
                selectedShare: null,
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '公海客户列表',
                selectedKey: {
                    688: true
                },
                expandedKey: null,
                customs: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '客户操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '导入客户',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.importCustom();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '拾取客户',
                            icon: 'pi pi-fw pi-user',
                            command: () => {
                                this.collectCustom();
                            }
                        },
                        {
                            separator: true
                        }, {
                            label: '新建客户',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                window.location.href = '/#/crmcustom/mycustomlist?createcustom=1';
                            }
                        },
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                excelTemplate: [{
                    name: '客户导入',
                    code: '178228300017733'
                }, {
                    name: '客户联系人导入',
                    code: '178710117163077'
                }],
                importTemplateId: null,
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getCustom(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.initFilters1();
                    this.loadMenuTree();
                    this.loadUserAuth();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('682', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '682',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '公海客户列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'publiccustom',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'name,code',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{crmcustomlist(where:" + JSON.stringify(listwhere) +
                    "){id simplename name address ownername ownerid city code country countryname customtype customtypename firstordertime mainchannel tel wechat qq remark}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.customs = jsonData.data.crmcustomlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            newCustomFilter() {
                this.displayQueryPlan = true;
                this.queryPlanObj = {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                };
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            collectCustom() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要拾取的客户');
                    return;
                }
                var customPublic = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    customPublic.push({
                        "id": this.selectedRow[k1]["id"],
                        "ownerid": sessionStorage.getItem("userid"),
                        "ownername": sessionStorage.getItem("username"),
                        "changehistory": [{
                            "id": "-1",
                            "changetype": 1,
                            "changetime": "",
                            "changeuserid": sessionStorage.getItem("userid"),
                            "parentid": this.selectedRow[k1]["id"],
                        }]
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{crmcustomlist(o:" + JSON.stringify(customPublic) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadData(1);
                        MessageTip.successmsg('拾取成功');
                    } else {
                        MessageTip.warnmsg('拾取失败');
                    }
                });
            },
            openCustom(v_data) {
                this.recordsubject = v_data.name;
                this.currentRow = {
                    bindpage: 'crmcustom',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            freshList() {
                this.displayMaximizable = false;
                this.butLoading = false;
                this.loadData(1);
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            importCustom() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateCustom')) {
                    MessageTip.warnmsg('请联系管理员开通新建客户权限');
                    return;
                }
                this.importDisplay = true;
            },
            closeImport() {
                this.importDisplay = false;
            },
            onImportUpload(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    requests.plat_requests.post(
                        '../calf/plat/ExcelTemplateImport?appkey=' + sessionStorage.getItem(
                            "appkey"), {
                            templateid: this.importTemplateId,
                            filepatch: retObj.patch,
                        }
                    ).then(res => {
                        this.importDisplay = false;
                        console.log('res:');
                        console.log(res);
                        if (res.errcode != "0") {
                            MessageTip.warnmsg('数据导入失败');
                        } else {
                            MessageTip.successmsg('数据导入成功');
                        }
                    });
                } else {
                    this.importDisplay = false;
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            beforeImportUpload(event) {
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', '1');
                event.formData.append('attachttype', '1');
            },
            getTemplateFilePatch() {
                if (this.importTemplateId == '178228300017733') {
                    location.href = '/calf/plat/file/exceltemplate/客户导入模板.xlsx?appkey=' + sessionStorage.getItem(
                        "appkey");
                    return;
                }
                if (this.importTemplateId == '178710117163077') {
                    location.href = '/calf/plat/file/exceltemplate/客户联系人导入模板.xlsx?appkey=' + sessionStorage.getItem(
                        "appkey");
                    return;
                }
                MessageTip.warnmsg('请选择导入类型');
                return;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
        }
    }
</script>